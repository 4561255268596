import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  props: {
    title: {
      type: String,
      "default": "Pro"
    },
    subplankey: {
      type: String,
      "default": "selectedRegionSubTier"
    },
    pricePerMonth: {
      type: Number,
      "default": 60
    },
    pricePerYear: {
      type: Number,
      "default": 480
    },
    discountedPricePerMonth: {
      type: Number,
      "default": 0
    },
    discountedPricePerYear: {
      type: Number,
      "default": 0
    },
    monthInterval: {
      type: String,
      "default": "Month"
    },
    annualInterval: {
      type: String,
      "default": "Year"
    },
    currencyIso: {
      type: String,
      "default": "USD"
    },
    priorPlan: {
      type: String,
      "default": ""
    },
    featuresArr: {
      type: Array,
      "default": function _default() {
        return [{
          html: "Data for all <b>6 regions</b>"
        },
        // { text: `Segment financials` },
        {
          text: "Premium valuation metrics"
        }, {
          text: "Unlimited saved screens"
        }, {
          text: "20 years financial charting"
        }, {
          text: "5 years analyst estimates"
        }, {
          text: "Priority support"
        }];
      }
    },
    currentPlan: {
      type: Boolean,
      "default": false
    },
    proUser: {
      type: Boolean,
      "default": false
    },
    subplanArr: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    sub: {
      type: String,
      "default": ""
    },
    email: {
      type: String,
      "default": ""
    },
    unauth: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      referralCode: "price21",
      regionArr: [{
        text: "North America",
        value: "2021_v0-region-nam"
      }, {
        text: "Europe",
        value: "2021_v0-region-europe"
      }, {
        text: "Asia / Pacific",
        value: "2021_v0-region-apac"
      }, {
        text: "LATAM, M. East & Africa (Other)",
        value: "2021_v0-region-other"
      }]
    };
  },
  computed: {
    proCard: function proCard() {
      var cardTitle = this.title; // you're specifying title === "Pro" as a condition of this card"
      return cardTitle === "Pro"; // abstracting in case you change
    },
    plusCard: function plusCard() {
      var cardTitle = this.title; // you're specifying title === "Plus" as a condition of this card"
      return cardTitle === "Plus"; // abstracting in case you change
    },
    freeCard: function freeCard() {
      var cardTitle = this.title; // you're specifying title === "Free" as a condition of this card"
      return cardTitle === "Free";
    },
    // hostedPage() {
    //   const sub = this.sub
    //   const email = this.email
    //   const freq = this.freq
    //   const proCard = this.proCard
    //   if (proCard) {
    //     const plan = "2022_v0-global"
    //     const plan_code = `${freq}-${plan}` // `a-2022_v0-global`
    //     //https://:your_subdomain.recurly.com/subscribe/:plan_code/:account_code/:username
    //     const baseUrl = `https://tikr.recurly.com/subscribe`
    //     // const plan_code = `tikr-test` // `a-2022_v0-global`
    //     const planUrl = `${baseUrl}/${plan_code}/${sub}/${email}?email=${email}&sub=${sub}`
    //     return planUrl
    //   } else if (this.selectSubTier) {
    //     // or you're specifying title === "Region" as a condition of this card"
    //     const plan = this.selectedSubPlan ? this.selectedSubPlan.value : ""
    //     if (plan) {
    //       const plan_code = `${freq}-${plan}` // `a-2022_v0-global`
    //       const baseUrl = `https://tikr.recurly.com/subscribe`
    //       const planUrl = `${baseUrl}/${plan_code}/${sub}/${email}?email=${email}&sub=${sub}`
    //       return planUrl
    //     } else {
    //       return 0
    //     }
    //   } else {
    //     return 0 // idk man
    //   }
    // },
    periodInt: function periodInt() {
      return this.$store.state.config.subscriptionIntervalInt;
    },
    freq: function freq() {
      // if(this.periodInt > 1) {
      // 	// special period, for testing
      // }
      return this.periodInt === 1 ? "a" : "m";
    },
    price: function price() {
      return this.periodInt ? this.pricePerYear : this.pricePerMonth;
    },
    discountedPrice: function discountedPrice() {
      return this.periodInt ? this.discountedPricePerYear : this.discountedPricePerMonth;
    },
    timeInterval: function timeInterval() {
      return this.periodInt ? this.annualInterval : this.monthInterval;
    },
    listHeaderMessage: function listHeaderMessage() {
      if (this.priorPlan) {
        return "Benefits of ".concat(this.priorPlan, ", Plus:");
      } else {
        return "Highlights of ".concat(this.title, " Plan:");
      }
    },
    disabledButtonMessage: function disabledButtonMessage() {
      return "Current Plan";
    },
    disabledFreeMessage: function disabledFreeMessage() {
      return "Included in Current Plan";
    },
    unauthFreeMessage: function unauthFreeMessage() {
      return "No credit card required!";
    },
    menuButtonMessage: function menuButtonMessage() {
      return "Click to Select ".concat(this.title);
    },
    activeButtonMessage: function activeButtonMessage() {
      var title = this.title;
      if (this.selectSubTier) {
        var subplan = this.selectedSubPlan;
        return "Purchase ".concat(subplan.text, " ").concat(title, " Plan");
      } else {
        return "Purchase ".concat(title, " Plan");
      }
    },
    selectedSubPlan: {
      get: function get() {
        return this.$store.state.config[this.subplankey];
      },
      set: function set(e) {
        this.$store.commit("config/setConfig", {
          type: this.subplankey,
          value: e
        });
      }
    },
    selectSubTier: function selectSubTier() {
      return this.title === "Region"; // TODO: if the inteface changes...
    },
    darkMode: {
      get: function get() {
        return this.$store.state.config.darkMode;
      },
      set: function set() {
        this.$store.commit("config/setDarkMode", {
          vuetify: this.$vuetify
        });
      }
    }
  },
  mounted: function mounted() {
    var ref = this.$route.query.ref;
    if (ref) {
      this.referralCode = ref;
    }
  },
  methods: {
    proceedToPayment: function proceedToPayment() {
      if (this.unauth) {
        this.$router.push({
          path: "/account/subs",
          query: {
            ref: this.referralCode
          }
        });
        return;
      }
      var proCard = this.proCard;
      var plusCard = this.plusCard;
      var freq = this.freq;
      if (proCard || plusCard) {
        // const plan = "2021_v0-global"
        // const plan = proCard ? "2022_v0-pro" : "2022_v0-plus"
        var plan = proCard ? "2022_v0-global" : "2022_v0-plus";
        var plan_code = "".concat(freq, "-").concat(plan); // `a-2021_v0-global`
        this.$store.dispatch("config/updateConfigSync", {
          type: "selectedPlanCode",
          value: plan_code
        });
        if (this.darkMode) {
          this.darkMode = false;
        }
        this.$store.dispatch("config/updateConfigSync", {
          type: "paymentDialog",
          value: true
        });
      } else if (this.selectSubTier) {
        // or you're specifying title === "Region" as a condition of this card"
        var _plan = this.selectedSubPlan ? this.selectedSubPlan.value : "";
        if (_plan) {
          var _plan_code = "".concat(freq, "-").concat(_plan); // `a-2021_v0-global`
          this.$store.dispatch("config/updateConfigSync", {
            type: "selectedPlanCode",
            value: _plan_code
          });
          if (this.darkMode) {
            this.darkMode = false;
          }
          this.$store.dispatch("config/updateConfigSync", {
            type: "paymentDialog",
            value: true
          });
        }
      }
    }
  }
});